import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import actions from 'src/store/actions';
import { useDispatch } from 'react-redux';
import { Wrapper, InfoIcon, Text } from './styles';

const InfoText = ({ body, boldText, text }) => {
  const dispatch = useDispatch();

  const renderModal = () => (
    <MDXProvider>
      <MDXRenderer>
        {body}
      </MDXRenderer>
    </MDXProvider>
  );

  const clickHandler = () => {
    dispatch({ type: actions.CLEAR_MODAL });
    dispatch({ type: actions.SET_MODAL_TYPE, payload: 'vertical' });
    dispatch({ type: actions.SET_MODAL_CONTENTS, payload: renderModal() });
    // dispatch({type: actions.SET_MODAL_IMAGE, payload: assetQuery[modalId].childImageSharp.fluid})
    dispatch({ type: actions.SET_SHOW_MODAL, payload: true });
  };
  return (
    <Wrapper onClick={() => clickHandler()}>
      <InfoIcon>i</InfoIcon>
      <Text>
        <p><span>{boldText}</span></p>
        <p>{text}</p>
      </Text>
    </Wrapper>
  );
};

export default InfoText;
