import React from 'react';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { useDispatch } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';
import HoverDot from 'src/components/desktop/hoverDot/hoverDot';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import actions from 'src/store/actions';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import { SlideLarge } from '../../_styles';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    mainscene: file(relativePath: { eq: "chapter_4/sub_12/4-12-2-bg.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  scenelayer1: file(relativePath: { eq: "chapter_4/sub_12/4-12-2-parallax1.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  scenelayer2: file(relativePath: { eq: "chapter_4/sub_12/4-12-2-flag.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  mainModalImage: file(relativePath: { eq: "chapter_4/sub_12/4-12-2-mainModal.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 762)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  modal41221: file(relativePath: { eq: "chapter_4/sub_12/4-12-2-modal1.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 762)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  modal41222: file(relativePath: { eq: "chapter_4/sub_12/4-12-2-modal2.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 313)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  modal41223: file(relativePath: { eq: "chapter_4/sub_12/4-12-2-modal3.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 313)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  modal41224: file(relativePath: { eq: "chapter_4/sub_12/4-12-2-modal4.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 313)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  modal41225: file(relativePath: { eq: "chapter_4/sub_12/4-12-2-modal5.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 313)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  modal41226: file(relativePath: { eq: "chapter_4/sub_12/4-12-2-modal6.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 762)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  modal41227: file(relativePath: { eq: "chapter_4/sub_12/4-12-2-modal7.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 313)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  modal41228: file(relativePath: { eq: "chapter_4/sub_12/4-12-2-modal8.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 313)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  modal41229: file(relativePath: { eq: "chapter_4/sub_12/4-12-2-modal9.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 313)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  }
  `);

  const dispatch = useDispatch();

  const renderModal = (body) => (
    <MDXProvider>
      <MDXRenderer>
        {body}
      </MDXRenderer>
    </MDXProvider>
  );

  const clickHandler = (modalId) => {
    dispatch({ type: actions.CLEAR_MODAL });
    dispatch({ type: actions.SET_MODAL_TYPE, payload: modalId === 'modal41221' || modalId === 'modal41226' ? 'horizontal' : 'vertical' });
    dispatch({ type: actions.SET_MODAL_CONTENTS, payload: renderModal(query[modalId].body) });
    dispatch({ type: actions.SET_MODAL_IMAGE, payload: assetQuery[modalId] && assetQuery[modalId].childImageSharp.fixed });
    dispatch({ type: actions.SET_SHOW_MODAL, payload: true });
  };
  return (
    <SlideLarge>
      <ParallaxMordo column>
        <Mordo
          mainscene
          background={{ image: assetQuery.mainscene.childImageSharp.fluid }}
          move={{ x: -20, y: -10 }}
        />

        <Mordo
          scenelayer={{ fromLeft: '0', fromBottom: '0' }}
          scene={2}
          move={{ x: 5, y: 5 }}
          background={{}}
          opacity={1}
          customStyle={{ overflow: 'visible' }}
        >
          <div style={{
            marginLeft: '12%',
            marginTop: '7%',
            position: 'absolute',
            width: '85vw',
            height: 'calc(40vw*9/16)',
            backgroundImage: `url(${assetQuery.scenelayer1.childImageSharp.fluid.src})`,
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
          >
            {query.hoverDot.exports.hoverInfo.map((dot) => <HoverDot key={dot.identifier} alternative insideText={dot.insideText} positionLeft={dot.positionLeft} positionTop={dot.positionTop} main={dot.main} clickDot={() => clickHandler(dot.identifier)} />)}
          </div>
        </Mordo>
        <Mordo
          scenelayer={{ fromLeft: '18%', fromBottom: '40%' }}
          scene={1}
          move={{ x: -0.3, y: -5 }}
          background={{ image: assetQuery.scenelayer2.childImageSharp.fluid }}
          opacity={1}
          customStyle={{ width: '5%' }}
        />
        <Mordo
          scene={1}
          move={{ x: -5, y: -5 }}
          sceneLayer={{ fromBottom: '20%' }}
        >
          <LeftColContent body={query.mdx.body} style={{ marginTop: '20%', marginLeft: '5%' }} images={[assetQuery.mainModalImage.childImageSharp.fixed]} customWidth customTreshold={0.6} />
        </Mordo>

      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
