import React from 'react';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import { useDispatch } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import actions from 'src/store/actions';
import AudioPlayer from 'src/components/desktop/audioPlayer/audioPlayer';
import { getLangFromPath } from 'src/utils';
import { BlockContainer } from '../../_styles';
import SubSlider from './subSlider';
import { mediaServer } from '../../../../../../globals';

const Slide = ({ query, audio = 'Ludomił Rayski' }) => {
  const assetQuery = useStaticQuery(graphql`
    query {
      mainModalImage: file(
        relativePath: { eq: "chapter_4/sub_12/4-12-2-mainModal.jpg" }
      ) {
        childImageSharp {
          fixed(width: 506, height: 762) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
            width
          }
        }
      }
      modal41221: file(
        relativePath: { eq: "chapter_4/sub_12/4-12-2-modal1.jpg" }
      ) {
        childImageSharp {
          fixed(width: 506, height: 762) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
            width
          }
        }
      }
      modal41226: file(
        relativePath: { eq: "chapter_4/sub_12/4-12-2-modal6.jpg" }
      ) {
        childImageSharp {
          fixed(width: 506, height: 762) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
            width
          }
        }
      }
    }
  `);

  const dispatch = useDispatch();

  const renderModal = (body) => (
    <MDXProvider>
      <MDXRenderer>{body}</MDXRenderer>
    </MDXProvider>
  );

  const clickHandler = (modalId) => {
    dispatch({ type: actions.CLEAR_MODAL });
    dispatch({ type: actions.SET_MODAL_TYPE, payload: 'mobile' });
    dispatch({
      type: actions.SET_MODAL_CONTENTS,
      payload: renderModal(query[modalId].body),
    });
    dispatch({
      type: actions.SET_MODAL_IMAGE,
      payload: assetQuery[modalId] && assetQuery[modalId].childImageSharp.fixed,
    });
    dispatch({ type: actions.SET_SHOW_MODAL, payload: true });
  };

  return (
    <BlockContainer height="1280px">
      <BackgroundPhoto
        fixed={query.file.childImageSharp.fixed}
        alt="slide-photo"
      >
        <TextAnimation
          containerStyle={{ height: '30%', marginTop: '12rem' }}
          body={query.mdx.body}
        />
        <AudioPlayer audio={[`${mediaServer}/webm/${getLangFromPath().toLowerCase()}/4134rayski.webm`, `${mediaServer}/mp4/${getLangFromPath().toLowerCase()}/4134rayski.mp4`]}
          audioImage={query.audioImage.childImageSharp.fluid} audioDescription={audio} size={2} />
        <SubSlider query={query} clickHandler={clickHandler} style={{ marginTop: '2rem' }} />
      </BackgroundPhoto>
    </BlockContainer>
  );
};

export default Slide;
