import React from 'react';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';
import Slide1 from 'src/slides/desktop/hu/chapter_4/sub_12/slide1';
import Slide2 from 'src/slides/desktop/hu/chapter_4/sub_12/slide2';
import Slide3 from 'src/slides/desktop/hu/chapter_4/sub_12/slide3';
import Slide4 from 'src/slides/desktop/hu/chapter_4/sub_12/slide4';
import Slide5 from 'src/slides/desktop/hu/chapter_4/sub_12/slide5';
import Slide6 from 'src/slides/desktop/hu/chapter_4/sub_12/slide6';
import SEO from 'src/components/_shared/seo';
import WrapperMobile from 'src/slides/mobile/hu/chapter_4/sub_12/wrapperMobile';

import { isMobile } from 'react-device-detect';

const allSlides = [<Slide1 />, <Slide2 />, <Slide3 />, <Slide4 />, <Slide5 />, <Slide6 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Ki volt Lengyelország szövetségese a lengyel-bolsevik háborúban | A varsói csata" lang="hu" description="Ukránok, magyarok, amerikaiak, franciák – rájuk számíthattunk 1920-ban." />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Ki volt Lengyelország szövetségese a lengyel-bolsevik háborúban | A varsói csata" lang="hu" description="Ukránok, magyarok, amerikaiak, franciák – rájuk számíthattunk 1920-ban." />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
