import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_4/sub_12/slide5';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    leftColumn: mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "slide-4-12-5"}}) {
        body
      },
      hoverDot: mdx(
        frontmatter: {
          language: { eq: "HU" }
          title: { eq: "gb-fr-dots" }
        }
      ) {
        exports {
          hoverInfo {
            positionLeft
            positionTop
            identifier
            insideText
          }
        }
      },
      modal41251:  mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "gb-fr-modals"}, selector: {eq: "modal41251"}}) {
        body
      },
      modal41252:  mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "gb-fr-modals"}, selector: {eq: "modal41252"}}) {
        body
      },
      audio1: mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "slide-4-12-5-audio1"}}) {
        excerpt(pruneLength: 100000)
      },
      audio2: mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "slide-4-12-5-audio2"}}) {
        excerpt(pruneLength: 100000)
      },
  }
  `);

  return (
    <Template query={query} />
  );
};


export default Slide;
