import React from 'react';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import { BlockContainer, ChpaterTitle } from '../../_styles';

const Slide = ({ query, title }) => (
  <BlockContainer height="900px">
    <BackgroundPhoto fixed={query.file.childImageSharp.fixed} alt="slide-photo">
      <ChpaterTitle marginTop="8rem">{title}</ChpaterTitle>
      <TextAnimation containerStyle={{ height: '11%', marginTop: '1rem', textAlign: 'center' }} body={query.mdx.body} />
    </BackgroundPhoto>
  </BlockContainer>
);

export default Slide;
