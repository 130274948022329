import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_4/sub_12/slide3';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    leftColumn: mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "slide-4-12-3"}}) {
        body
      },
      labels: mdx(
        frontmatter: {
          language: {eq: "HU"}
          title: {eq: "slide-4-12-3-labels"}
        }
        ) {
        exports {
          labels {
            meters
            millions
            caption1
            caption2
            caption3
          }
        }
      },
      hoverDot: mdx(
        frontmatter: {
          language: { eq: "HU" }
          title: { eq: "hungary-dots" }
        }
      ) {
        exports {
          hoverInfo {
            positionLeft
            positionTop
            identifier
          }
        }
      },
      modal41231:  mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "hungary-modals"}, selector: {eq: "modal41231"}}) {
        body
      },
      modal41232:  mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "hungary-modals"}, selector: {eq: "modal41232"}}) {
        body
      },
  }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
