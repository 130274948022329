import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

export const InfoIcon = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 1.25rem;
  height: 1.25rem;
  font-family: "Red Hat Display", sans-serif;
  border-radius: 100%;
  color: white;
  background-color: #de2831;
  font-weight: 700;
  font-size: 1rem;
  margin: 0 5px;
`;

export const Text = styled.div`
    font-family: "Red Hat Display";
    color: #f9f8f3;
    margin-left: 0.5rem;

    span{
      font-weight: bold;
    }
`;
