import React from 'react';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled, { keyframes } from 'styled-components';
import { SlideLarge } from '../../_styles';
import { colors } from '../../../../../../globals';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

const slideUp = keyframes`
from {
  opacity: 0;
  transform: translate3d(0,70px,0);
}
to {
  opacity: 1;
  transform: translate3d(0,0,0);
}
`;

const GridContainer = styled.div`
  display: grid;
  width: 75vw;
  grid-template: 1fr / repeat(10, 1fr);
  height: 20%;
  margin-left: 15vw;
  margin-top: 26vh;
`;
const BigHeader = styled.p`
grid-column:  span 10;
text-align: center;
${colors.light};
font-family: 'Abril Fatface', 'Prata';
font-size: 9.2rem;
letter-spacing: 0.3rem;
animation: ${slideUp} 1s cubic-bezier(0.78, 0, 0.235, 1) 200ms forwards;
`;

const BottomText = styled.p`
font-family: 'Red Hat Display', 'Open Sans';
font-size: 1.5rem;
text-align: center;
${colors.light};
grid-column: span 10;
animation: ${slideUp} 1.4s cubic-bezier(0.78, 0, 0.235, 1) 200ms forwards;
`;

const TextContainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
h2 {
  text-align: center;
  ${colors.light};
  font-family: 'Abril Fatface', 'Prata';
  font-size: 8.2rem;
  letter-spacing: 0.3rem;
  max-width: 75vw;
  animation: ${slideUp} 1s cubic-bezier(0.78, 0, 0.235, 1) 200ms forwards;
  margin: 0 auto;
}
p {
  font-family: 'Red Hat Display', 'Open Sans';
  font-size: 1.5rem;
  text-align: center;
  ${colors.light};
  animation: ${slideUp} 1.4s cubic-bezier(0.78, 0, 0.235, 1) 200ms forwards;
  width: 50vw;
  margin: 0 auto;
}
`


const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    mainscene: file(relativePath: { eq: "chapter_4/sub_12/4-12-1-bg.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  flag1: file(relativePath: { eq: "chapter_4/sub_12/4-12-1-flag1.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  flag2: file(relativePath: { eq: "chapter_4/sub_12/4-12-1-flag2.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  flag3: file(relativePath: { eq: "chapter_4/sub_12/4-12-1-flag3.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  flag4: file(relativePath: { eq: "chapter_4/sub_12/4-12-1-flag4.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  flag5: file(relativePath: { eq: "chapter_4/sub_12/4-12-1-flag5.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  flag6: file(relativePath: { eq: "chapter_4/sub_12/4-12-1-flag6.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  flag7: file(relativePath: { eq: "chapter_4/sub_12/4-12-1-flag7.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  flag8: file(relativePath: { eq: "chapter_4/sub_12/4-12-1-flag8.png"}) {
      childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },

  }
  `);





  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: -20, y: -10 }}
          background={{ image: assetQuery.mainscene.childImageSharp.fluid }}
        />
        <Mordo
          scene={1}
          flexi
          move={{ x: 10, y: 20 }}
        >
          <TextContainer>
            <GridContainer>
              <div />
              <Img fluid={assetQuery.flag1.childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
              <Img fluid={assetQuery.flag2.childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
              <Img fluid={assetQuery.flag3.childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
              <Img fluid={assetQuery.flag4.childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
              <Img fluid={assetQuery.flag5.childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
              <Img fluid={assetQuery.flag6.childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
              <Img fluid={assetQuery.flag7.childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
              <Img fluid={assetQuery.flag8.childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
              <div />
            </GridContainer>
            <MDXProvider>
              <MDXRenderer>{query.leftColumn.body}</MDXRenderer>
            </MDXProvider>
          </TextContainer>
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
