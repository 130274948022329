import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import PhotoWithDescription from 'src/components/mobile/photoWithDescription/photoWithDescription';
import { BlockContainer } from '../../_styles';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
    query {
      flag1: file(relativePath: { eq: "chapter_4/sub_12/4-12-6-flag1.png" }) {
        childImageSharp {
          fixed(width: 168) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      flag2: file(relativePath: { eq: "chapter_4/sub_12/4-12-6-flag2.png" }) {
        childImageSharp {
          fixed(width: 168) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      flag3: file(relativePath: { eq: "chapter_4/sub_12/4-12-6-flag3.png" }) {
        childImageSharp {
          fixed(width: 168) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      person1: file(
        relativePath: { eq: "chapter_4/sub_12/4-12-6-person1.png" }
      ) {
        childImageSharp {
          fixed(width: 300, quality: 90) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      person2: file(
        relativePath: { eq: "chapter_4/sub_12/4-12-6-person2.png" }
      ) {
        childImageSharp {
          fixed(width: 300, quality: 90) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      person3: file(
        relativePath: { eq: "chapter_4/sub_12/4-12-6-person3.png" }
      ) {
        childImageSharp {
          fixed(width: 300, quality: 90) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);
  return (
    <BlockContainer height="1400px">
      <BackgroundPhoto
        fixed={query.file.childImageSharp.fixed}
        alt="slide-photo"
      >
        <PhotoWithDescription
          image={assetQuery.person1.childImageSharp.fixed}
          name={query.modal41261.frontmatter.name}
          bodyModal={query.modal41261.body}
          flagImg={assetQuery.flag1.childImageSharp.fixed}
        />
        <PhotoWithDescription
          image={assetQuery.person2.childImageSharp.fixed}
          name={query.modal41262.frontmatter.name}
          bodyModal={query.modal41262.body}
          flagImg={assetQuery.flag2.childImageSharp.fixed}
        />
        <PhotoWithDescription
          image={assetQuery.person3.childImageSharp.fixed}
          name={query.modal41263.frontmatter.name}
          bodyModal={query.modal41263.body}
          flagImg={assetQuery.flag3.childImageSharp.fixed}
        />
      </BackgroundPhoto>
    </BlockContainer>
  );
};

export default Slide;
