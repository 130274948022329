import React from 'react';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { useDispatch } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';
import HoverDot from 'src/components/desktop/hoverDot/hoverDot';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import actions from 'src/store/actions';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import AudioPlayer from 'src/components/desktop/audioPlayer/audioPlayer';
import { getLangFromPath } from 'src/utils';
import { SlideLarge, ResponsiveImage } from '../../_styles';
import { mediaServer } from '../../../../../../globals';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    mainscene: file(relativePath: { eq: "chapter_4/sub_12/4-12-5-bg.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1920, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  flag1: file(relativePath: { eq: "chapter_4/sub_12/4-12-5-flag1.png"}) {
      childImageSharp {
          fixed(width: 168) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
  },
  flag2: file(relativePath: { eq: "chapter_4/sub_12/4-12-5-flag2.png"}) {
      childImageSharp {
          fixed(width: 168) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
  },
  person1: file(relativePath: { eq: "chapter_4/sub_12/4-12-5-person1.png"}) {
    childImageSharp {
        fluid(maxWidth: 492, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
},
person2: file(relativePath: { eq: "chapter_4/sub_12/4-12-5-person2.png"}) {
    childImageSharp {
        fluid(maxWidth: 363, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
},
modal41251: file(relativePath: { eq: "chapter_4/sub_12/4-12-5-modal1.jpg"}) {
  childImageSharp {
    fixed(width: 506, height: 762)  {
      ...GatsbyImageSharpFixed_withWebp_noBase64
      width
    }
  }
},
modal41252: file(relativePath: { eq: "chapter_4/sub_12/4-12-5-modal2.jpg"}) {
  childImageSharp {
    fixed(width: 506, height: 762)  {
      ...GatsbyImageSharpFixed_withWebp_noBase64
      width
    }
  }
},
audio1: file(relativePath: { eq: "chapter_4/sub_12/4-12-5-audio1.png"}) {
  childImageSharp {
      fluid(maxWidth: 275) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
},
audio2: file(relativePath: { eq: "chapter_4/sub_12/4-12-5-audio2.png"}) {
  childImageSharp {
      fluid(maxWidth: 275) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
},
  }
  `);

  const dispatch = useDispatch();

  const renderModal = (body) => (
    <MDXProvider>
      <MDXRenderer>
        {body}
      </MDXRenderer>
    </MDXProvider>
  );

  const clickHandler = (modalId) => {
    dispatch({ type: actions.CLEAR_MODAL });
    dispatch({ type: actions.SET_MODAL_TYPE, payload: 'horizontal' });
    dispatch({ type: actions.SET_MODAL_CONTENTS, payload: renderModal(query[modalId].body) });
    dispatch({ type: actions.SET_MODAL_IMAGE, payload: assetQuery[modalId] && assetQuery[modalId].childImageSharp.fixed });
    dispatch({ type: actions.SET_SHOW_MODAL, payload: true });
  };

  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: 20, y: -10 }}
          background={{ image: assetQuery.mainscene.childImageSharp.fluid }}
        />
        <Mordo
          scene={1}
          move={{ x: 10, y: 20 }}
          background={{ color: 'transparent' }}
          opacity={1}
        >
          <ResponsiveImage fixed={assetQuery.flag1.childImageSharp.fixed} style={{ position: 'absolute', left: '26vw', top: '34vh' }} />
          <ResponsiveImage
            fluid={assetQuery.person1.childImageSharp.fluid}
            style={{
              position: 'absolute', left: '10vw', bottom: '0', width: '25%', height: '75%',
            }}
          />
        </Mordo>
        <Mordo
          scene={2}
          move={{ x: -20, y: -10 }}
          background={{ color: 'transparent' }}
          opacity={1}
        >
          <ResponsiveImage fixed={assetQuery.flag2.childImageSharp.fixed} style={{ position: 'absolute', right: '8vw', top: '16vh' }} />
          <ResponsiveImage
            fluid={assetQuery.person2.childImageSharp.fluid}
            style={{
              position: 'absolute', right: '8vw', top: '0', width: '20%', height: '70%',
            }}
          />
        </Mordo>
        <Mordo
          scene={3}
          flexi
          move={{ x: -10, y: 20 }}
        >

          <LeftColContent
            body={query.leftColumn.body}
            style={{
              justifyContent: 'flex-start', paddingTop: '15vh', textAlign: 'center', width: '90vw',
            }}
          />
          <div style={{
            position: 'absolute', top: '55vh', left: '75vw', display: 'flex',
          }}
          >
            <AudioPlayer audio={[`${mediaServer}/webm/${getLangFromPath().toLowerCase()}/4125sosnkowski.webm`, `${mediaServer}/mp4/${getLangFromPath().toLowerCase()}/4125sosnkowski.mp4`]} audioImage={assetQuery.audio1.childImageSharp.fluid} size={2} audioDescription={query.audio1.excerpt} />
            <AudioPlayer audio={[`${mediaServer}/webm/${getLangFromPath().toLowerCase()}/4125dizier.webm`, `${mediaServer}/mp4/${getLangFromPath().toLowerCase()}/4125dizier.mp4`]} audioImage={assetQuery.audio2.childImageSharp.fluid} size={2} audioDescription={query.audio2.excerpt} />
          </div>
          {query.hoverDot.exports.hoverInfo.map((dot) => <HoverDot key={dot.identifier} insideText={dot.insideText} positionLeft={dot.positionLeft} positionTop={dot.positionTop} clickDot={() => clickHandler(dot.identifier)} />)}
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
