import React, { useState } from 'react';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { useDispatch } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';
import { useInView } from 'react-intersection-observer';
import InfoDot from 'src/components/desktop/infoDot/infoDot';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import actions from 'src/store/actions';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import Counter from 'src/components/desktop/counter/counter';
import VideoMP4 from 'src/assets/videos/chapter_4/4-12-3-bg.mp4';
import Video from 'src/assets/videos/chapter_4/4-12-3-bg.webm';
import BackgroundAnimation from 'src/components/desktop/backgroundAnimation/backgroundAnimation';
import { SlideLarge } from '../../_styles';


const Slide = ({ query }) => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  const [windowWidth, setWindoWidth] = useState(window.innerWidth);

  window.addEventListener('resize', () => setWindoWidth(window.innerWidth));

  const assetQuery = useStaticQuery(graphql`
  query {
  flag: file(relativePath: { eq: "chapter_4/sub_12/4-12-3-flag.png"}) {
      childImageSharp {
          fixed(width: 168) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
  },
  mainModalImage: file(relativePath: { eq: "chapter_4/sub_12/4-12-3-modal1.jpg"}) {
    childImageSharp {
      fixed(width: 506, height: 313)  {
        ...GatsbyImageSharpFixed_withWebp_noBase64
        width
      }
    }
  },
  moon: file(relativePath: { eq: "chapter_4/sub_12/4-12-3-moon.png"}) {
      childImageSharp {
          fluid(maxWidth: 312, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  info: file(relativePath: { eq: "chapter_4/sub_12/4-12-3-info.png"}) {
      childImageSharp {
          fluid(maxWidth: 936, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  }
  `);

  const dispatch = useDispatch();

  const renderModal = (body) => (
    <MDXProvider>
      <MDXRenderer>
        {body}
      </MDXRenderer>
    </MDXProvider>
  );

  const clickHandler = (modalId) => {
    dispatch({ type: actions.CLEAR_MODAL });
    dispatch({ type: actions.SET_MODAL_TYPE, payload: 'vertical' });
    dispatch({ type: actions.SET_MODAL_CONTENTS, payload: renderModal(query[modalId].body) });
    // dispatch({type: actions.SET_MODAL_IMAGE, payload: assetQuery[modalId].childImageSharp.fluid})
    dispatch({ type: actions.SET_SHOW_MODAL, payload: true });
  };

  return (
    <SlideLarge ref={ref}>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: 20, y: -10 }}
          background={{ color: 'transparent' }}
        >
          <BackgroundAnimation src={Video} mp4={VideoMP4} responsive />
        </Mordo>
        <Mordo
          scenelayer={{ fromLeft: '14%', fromBottom: '55%' }}
          scene={1}
          move={{ x: -0.8, y: 10 }}
          background={{ image: assetQuery.flag.childImageSharp.fixed }}
          opacity={1}
          customStyle={{ width: '8%' }}
        />
        <Mordo
          scene={1}
          flexi
          move={{ x: -10, y: 20 }}
        >
          <LeftColContent body={query.leftColumn.body} images={[assetQuery.mainModalImage.childImageSharp.fixed]} style={{ marginLeft: '5%' }} />
        </Mordo>
        <Mordo
          scenelayer={{ size: '85%', fromBottom: '10%', fromLeft: '80%' }}
          scene={2}
          move={{ x: 10, y: -10 }}
          background={{ image: assetQuery.moon.childImageSharp.fluid }}
        />
        <Mordo
          scenelayer={{ size: '100%', fromBottom: '10%', fromLeft: '45%' }}
          scene={2}
          move={{ x: 20, y: -10 }}
          background={{ image: assetQuery.info.childImageSharp.fluid }}
        >
          {inView && (
            <>
              {query.hoverDot.exports.hoverInfo.map((dot) => <InfoDot key={dot.identifier} insideText="modal" positionLeft={dot.positionLeft} positionTop={dot.positionTop} clickDot={() => clickHandler(dot.identifier)} />)}
              <Counter
                number={825}
                unit={query.labels.exports.labels.meters}
                style={{
                  fontFamily: 'Red Hat Display',
                  position: 'absolute',
                  bottom: windowWidth < 1400 ? '39%' : '41%',
                  left: '30%',
                  textAlign: 'left',
                  alignItems: 'flex-start',
                  padding: 0,
                  marginLeft: '3rem',
                }}
              >
                <p style={{ fontSize: '0.9rem' }}>
                  <strong>{query.labels.exports.labels.caption1}</strong>
                  <br />
                  {query.labels.exports.labels.caption2}
                </p>
              </Counter>
              <Counter
                number={22}
                unit={query.labels.exports.labels.millions}
                style={{
                  fontFamily: 'Red Hat Display',
                  position: 'absolute',
                  top: '75%',
                  left: '50%',
                  textAlign: 'left',
                  alignItems: 'flex-start',
                  padding: 0,
                  marginLeft: '3rem',
                }}
              >
                <p style={{ fontSize: '0.9rem' }}>
                  <strong>{query.labels.exports.labels.caption3}</strong>
                </p>
              </Counter>
            </>
          )}
        </Mordo>

      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
