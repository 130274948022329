import React from 'react';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { useStaticQuery, graphql } from 'gatsby';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import { SlideLarge, ResponsiveImage } from '../../_styles';
import PhotoWithDescription from 'src/components/desktop/photoWithDescription/photoWithDescription';


const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    mainscene: file(relativePath: { eq: "chapter_4/sub_12/4-12-6-bg.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1920, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  flag1: file(relativePath: { eq: "chapter_4/sub_12/4-12-6-flag1.png"}) {
      childImageSharp {
          fixed(width: 168) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
  },
  flag2: file(relativePath: { eq: "chapter_4/sub_12/4-12-6-flag2.png"}) {
      childImageSharp {
          fixed(width: 168) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
  },
  flag3: file(relativePath: { eq: "chapter_4/sub_12/4-12-6-flag3.png"}) {
      childImageSharp {
          fixed(width: 168) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
  },
  person1: file(relativePath: { eq: "chapter_4/sub_12/4-12-6-person1.png"}) {
    childImageSharp {
        fixed(width: 300, quality: 90) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
},
  person2: file(relativePath: { eq: "chapter_4/sub_12/4-12-6-person2.png"}) {
    childImageSharp {
        fixed(width: 300, quality: 90) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
},
  person3: file(relativePath: { eq: "chapter_4/sub_12/4-12-6-person3.png"}) {
    childImageSharp {
        fixed(width: 300, quality: 90) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
},
  }
  `);

  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: 20, y: -10 }}
          background={{ image: assetQuery.mainscene.childImageSharp.fluid }}
        />
        <Mordo
          scene={2}
          move={{ x: 10, y: 20 }}
          background={{ color: 'transparent' }}
          flexi
          customStyle={{
            width: '90vw', margin: '25vh auto 0', paddingLeft: '8vw', maxWidth: '1200px', justifyContent: 'space-around',
          }}
        >

          <PhotoWithDescription
            image={assetQuery.person1.childImageSharp.fixed}
            modalBody={query['modal41261'].body}
          />
          <PhotoWithDescription
            image={assetQuery.person2.childImageSharp.fixed}
            modalBody={query['modal41262'].body}
          />
          <PhotoWithDescription
            image={assetQuery.person3.childImageSharp.fixed}
            modalBody={query['modal41263'].body}
          />
        </Mordo>
        <Mordo
          scene={1}
          flexi={{ alignItems: 'center' }}
          move={{ x: -10, y: 20 }}
          customStyle={{ marginTop: window.innerWidth < 1450 ? '47vh' : '34vh', zIndex: 999, pointerEvents: 'none' }}
        >
          <div style={{ width: '100vw', position: 'relative' }}>
            <ResponsiveImage fixed={assetQuery.flag1.childImageSharp.fixed} style={{ position: 'absolute', left: '27vw' }} />
            <ResponsiveImage fixed={assetQuery.flag2.childImageSharp.fixed} style={{ position: 'absolute', left: '45vw' }} />
            <ResponsiveImage fixed={assetQuery.flag3.childImageSharp.fixed} style={{ position: 'absolute', left: '60vw' }} />
            <LeftColContent
              body={query.leftColumn.body}
              style={{
                paddingTop: '6vh', textAlign: 'center', wordSpacing: '2rem',
              }}
            />
          </div>
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
