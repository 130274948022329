import React from 'react';
import BackgroundAnimation from 'src/components/mobile/backgroundAnimation/backgroundAnimation';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import InfoGraph from 'src/assets/images/chapter_4/sub_12/mobile/4-12-2-info-1.png';
import InfoGraph2 from 'src/assets/images/chapter_4/sub_12/mobile/4-12-2-info-2.png';
import { useInView } from 'react-intersection-observer';
import Counter from 'src/components/mobile/counter/counter';
import InfoText from 'src/components/mobile/infoText/infoText';
import mp4 from 'src/assets/videos/chapter_4/4-12-3-mobile.mp4';
import webm from 'src/assets/videos/chapter_4/4-12-3-mobile.webm';
import { BlockContainer } from '../../_styles';

const Slide = ({ query }) => {
  const [ref, inView] = useInView({
    threshold: 0,
  });
  const [ref2, inView2] = useInView({
    threshold: 0,
  });
  return (
    <BlockContainer height="2200px">
      <BackgroundAnimation
        src={webm}
        mp4={mp4}
      >
        <TextAnimation
          containerStyle={{ height: '38%' }}
          body={query.mdx.body}
        />
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={InfoGraph} alt="info-graphic" />
        </div>
        <div ref={ref} style={{ margin: '1rem 0' }}>
          <Counter number={825} visible={inView} unit="m" style={{ fontFamily: 'Red Hat Display' }} />
        </div>
        <InfoText boldText={query.labels.exports.labels.caption1} text={query.labels.exports.labels.caption2} body={query.modal41231.body} />
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
          <img src={InfoGraph2} alt="info-graphic2" />
        </div>
        <div ref={ref2} style={{ margin: '1rem 0' }}>
          <Counter number={22} visible={inView2} unit="mln" style={{ fontFamily: 'Red Hat Display' }} />
        </div>
        <InfoText boldText={query.labels.exports.labels.caption3} body={query.modal41232.body} />
      </BackgroundAnimation>
    </BlockContainer>
  );
};

export default Slide;
