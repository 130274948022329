import React from 'react';
import { ParallaxMordo, Mordo } from 'src/components/desktop/parallax/ParallaxMordo.jsx';
import { useDispatch } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';
import HoverDot from 'src/components/desktop/hoverDot/hoverDot';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import actions from 'src/store/actions';
import LeftColContent from 'src/components/desktop/leftColContent/leftColContent';
import AudioPlayer from 'src/components/desktop/audioPlayer/audioPlayer';
import { getLangFromPath } from 'src/utils';
import { SlideLarge } from '../../_styles';
import { mediaServer } from '../../../../../../globals';

const Slide = ({ query }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    mainscene: file(relativePath: { eq: "chapter_4/sub_12/4-12-4-bg.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
  },
  flag: file(relativePath: { eq: "chapter_4/sub_12/4-12-4-flag.png"}) {
      childImageSharp {
          fixed(width: 168) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
  },
  audio: file(relativePath: { eq: "chapter_4/sub_12/4-12-4-audio.png"}) {
    childImageSharp {
        fluid(maxWidth: 275) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
},
modal41241: file(relativePath: { eq: "chapter_4/sub_12/4-12-4-modal1.jpg"}) {
  childImageSharp {
    fixed(width: 506, height: 313)  {
      ...GatsbyImageSharpFixed_withWebp_noBase64
      width
    }
  }
},
modal41242: file(relativePath: { eq: "chapter_4/sub_12/4-12-4-modal2.jpg"}) {
  childImageSharp {
    fixed(width: 506, height: 313)  {
      ...GatsbyImageSharpFixed_withWebp_noBase64
      width
    }
  }
},
modal41243: file(relativePath: { eq: "chapter_4/sub_12/4-12-4-modal3.jpg"}) {
  childImageSharp {
    fixed(width: 506, height: 313)  {
      ...GatsbyImageSharpFixed_withWebp_noBase64
      width
    }
  }
},
modal41244: file(relativePath: { eq: "chapter_4/sub_12/4-12-4-modal4.jpg"}) {
  childImageSharp {
    fixed(width: 506, height: 313)  {
      ...GatsbyImageSharpFixed_withWebp_noBase64
      width
    }
  }
},
modal41245: file(relativePath: { eq: "chapter_4/sub_12/4-12-4-modal5.jpg"}) {
  childImageSharp {
    fixed(width: 506, height: 762)  {
      ...GatsbyImageSharpFixed_withWebp_noBase64
      width
    }
  }
},
modal41246: file(relativePath: { eq: "chapter_4/sub_12/4-12-4-modal6.jpg"}) {
  childImageSharp {
    fixed(width: 506, height: 313)  {
      ...GatsbyImageSharpFixed_withWebp_noBase64
      width
    }
  }
},
  }
  `);

  const dispatch = useDispatch();

  const renderModal = (body) => (
    <MDXProvider>
      <MDXRenderer>
        {body}
      </MDXRenderer>
    </MDXProvider>
  );

  const clickHandler = (modalId) => {
    dispatch({ type: actions.CLEAR_MODAL });
    dispatch({ type: actions.SET_MODAL_TYPE, payload: modalId === 'modal41245' ? 'horizontal' : 'vertical' });
    dispatch({ type: actions.SET_MODAL_CONTENTS, payload: renderModal(query[modalId].body) });
    dispatch({ type: actions.SET_MODAL_IMAGE, payload: assetQuery[modalId] && assetQuery[modalId].childImageSharp.fixed });
    dispatch({ type: actions.SET_SHOW_MODAL, payload: true });
  };

  return (
    <SlideLarge>
      <ParallaxMordo>
        <Mordo
          mainscene
          move={{ x: 20, y: -10 }}
          background={{ image: assetQuery.mainscene.childImageSharp.fluid }}
        />
        <Mordo
          scenelayer={{ fromLeft: '12%', fromBottom: '56%' }}
          scene={1}
          move={{ x: -0.8, y: 10 }}
          background={{ image: assetQuery.flag.childImageSharp.fixed }}
          opacity={1}
          customStyle={{ width: '10%' }}
        />
        <Mordo
          scene={1}
          flexi={{ column: true }}
          move={{ x: -10, y: 20 }}
        >
          {/* <ResponsiveImage fixed={assetQuery.flag.childImageSharp.fixed} style={{ position: 'absolute', left: '12%', top: '10%' }} /> */}
          <LeftColContent body={query.leftColumn.body} style={{ justifyContent: 'flex-start', paddingTop: '25vh', marginLeft: '5%' }} />
        </Mordo>
        <Mordo
          scene={2}
          move={{ x: 10, y: 20 }}
          background={{ color: 'transparent' }}
          opacity={1}
        >
          <AudioPlayer audio={[`${mediaServer}/webm/${getLangFromPath().toLowerCase()}/4134rayski.webm`, `${mediaServer}/mp4/${getLangFromPath().toLowerCase()}/4134rayski.mp4`]} audioImage={assetQuery.audio.childImageSharp.fluid} size={2} audioDescription={query.audio.excerpt} style={{ position: 'absolute', top: '50vh', left: '20vw' }} />
          {query.hoverDot.exports.hoverInfo.map((dot) => <HoverDot key={dot.identifier} alternative positionLeft={dot.positionLeft} positionTop={dot.positionTop} clickDot={() => clickHandler(dot.identifier)} />)}
        </Mordo>
      </ParallaxMordo>
    </SlideLarge>

  );
};


export default Slide;
