import React from 'react';
import BackgroundPhoto from 'src/components/mobile/backgroundPhoto/backgroundPhoto';
import TextAnimation from 'src/components/mobile/textAnimation/textAnimation';
import AudioPlayer from 'src/components/desktop/audioPlayer/audioPlayer';
import HoverDot from 'src/components/mobile/hoverDot/hoverDot';
import { useDispatch } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import actions from 'src/store/actions';
import { getLangFromPath } from 'src/utils';
import { BlockContainer } from '../../_styles';
import { mediaServer } from '../../../../../../globals';

const Slide = ({ query, audio1 = 'Kazimierz Sosnkowski', audio2 = 'Gérard Saint-Dizier' }) => {
  const assetQuery = useStaticQuery(graphql`
  query {
    audioImage1: file(relativePath: { eq: "chapter_4/sub_12/4-12-5-audio1.png"}) {
      childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    }
    audioImage2: file(relativePath: { eq: "chapter_4/sub_12/4-12-5-audio2.png"}) {
      childImageSharp {
          fluid(maxWidth: 275) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
    }
  }
  `);

  const dispatch = useDispatch();

  const renderModal = (body) => (
    <MDXProvider>
      <MDXRenderer>{body}</MDXRenderer>
    </MDXProvider>
  );

  const clickHandler = (modalId) => {
    dispatch({ type: actions.CLEAR_MODAL });
    dispatch({ type: actions.SET_MODAL_TYPE, payload: 'mobile' });
    dispatch({
      type: actions.SET_MODAL_CONTENTS,
      payload: renderModal(query[modalId].body),
    });
    dispatch({ type: actions.SET_SHOW_MODAL, payload: true });
  };

  return (
    <BlockContainer height="2200px">
      <BackgroundPhoto
        fixed={query.file.childImageSharp.fixed}
        alt="slide-photo"
      >
        <HoverDot positionLeft="5rem" positionTop="28rem" clickDot={() => clickHandler('modal41251')} />
        <HoverDot positionLeft="20rem" positionTop="28rem" clickDot={() => clickHandler('modal41252')} />
        <TextAnimation
          containerStyle={{ height: '75%', marginTop: '4rem' }}
          body={query.mdx.body}
        />
        <AudioPlayer
          audio={[`${mediaServer}/webm/${getLangFromPath().toLowerCase()}/4125sosnkowski.webm`, `${mediaServer}/mp4/${getLangFromPath().toLowerCase()}/4125sosnkowski.mp4`]}
          audioImage={assetQuery.audioImage1.childImageSharp.fluid}
          audioDescription={audio1}
          size={2}
        // style={{ marginTop: '2rem' }}
        />
        <AudioPlayer
          audio={[`${mediaServer}/webm/${getLangFromPath().toLowerCase()}/4125dizier.webm`, `${mediaServer}/mp4/${getLangFromPath().toLowerCase()}/4125dizier.mp4`]}
          audioImage={assetQuery.audioImage2.childImageSharp.fluid}
          audioDescription={audio2}
          size={2}
        // style={{ marginTop: '2rem' }}
        />
      </BackgroundPhoto>
    </BlockContainer>
  );
};

export default Slide;
